@import '~@angular/material/theming';
$custom-typography: mat-typography-config(
  $font-family: 'Open Sans, arial, sans-serif'
);
@include mat-core($custom-typography);
/*
$senet-mainColor-Gray: (
    50: #3f3e3e,
    100: #3f3e3e,
    200: #3f3e3e,
    300: #3f3e3e,
    400: #3f3e3e,
    500: #3f3e3e,
    600: #3f3e3e,
    700: #3f3e3e,
    800: #3f3e3e,
    900: #3f3e3e,
    A100: #3f3e3e,
    A200: #3f3e3e,
    A400: #3f3e3e,
    A700: #3f3e3e,
    contrast: (
        50: white,
        100: white,
        200: white,
        300: white,
        400: white,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
        A100: white,
        A200: white,
        A400: white,
        A700: white,
    )
);
*/
$senet-mainColor: (
    50: #7895b7,
    100: #6788ae,
    200: #587ca4,
    300: #4f6f93,
    400: #466383,
    500: #3d5672,
    600: #344961,
    700: #2b3d51,
    800: #223040,
    900: #192430,
    A100: #88a2c0,
    A200: #99afc8,
    A400: #aabcd1,
    A700: #11171f,
    contrast: (
        50: white,
        100: white,
        200: white,
        300: white,
        400: white,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
        A100: white,
        A200: white,
        A400: white,
        A700: white,
    )
);
$senet-green: (
    50: #1a4e23,
    100: #1a4e23,
    200: #1a4e23,
    300: #1a4e23,
    400: #1a4e23,
    500: #1a4e23,
    600: #1a4e23,
    700: #1a4e23,
    800: #1a4e23,
    900: #1a4e23,
    A100: #1a4e23,
    A200: #1a4e23,
    A400: #1a4e23,
    A700: #1a4e23,
    contrast: (
        50: white,
        100: white,
        200: white,
        300: white,
        400: white,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
        A100: white,
        A200: white,
        A400: white,
        A700: white,
    )
);
/*
$senet-accentColor-Gray: (
    50: #6a6868,
    100: #6a6868,
    200: #6a6868,
    300: #6a6868,
    400: #6a6868,
    500: #6a6868,
    600: #6a6868,
    700: #6a6868,
    800: #6a6868,
    900: #6a6868,
    A100: #6a6868,
    A200: #6a6868,
    A400: #6a6868,
    A700: #6a6868,
    contrast: (
        50: white,
        100: white,
        200: white,
        300: white,
        400: white,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
        A100: white,
        A200: white,
        A400: white,
        A700: white,
    )
);
*/
$senet-accentColor: (
    50: #3d5672,
    100: #ffffff,
    200: #ffffff,
    300: #ffffff,
    400: #ffffff,
    500: #f9f9f9,
    600: #ececec,
    700: #dfdfdf,
    800: #d3d3d3,
    900: #c6c6c6,
    A100: #fdfdfe,
    A200: #ffffff,
    A400: #ffffff,
    A700: #b9b9b9,
    contrast: (
        50: white,
        100: rgb(11, 10, 10),
        200: white,
        300: white,
        400: white,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
        A100: #466383,
        A200: white,
        A400: white,
        A700: white,
    )
);

$senet-app-primary: mat-palette($senet-mainColor, 700);
$senet-app-accent:  mat-palette($senet-accentColor, 50, A200, A100, A400);
$senet-app-green: mat-palette($senet-green);
$senet-app-warn:    mat-palette($mat-red);
$senet-app-theme: mat-light-theme($senet-app-primary, $senet-app-accent, $senet-app-warn);
@include angular-material-theme($senet-app-theme);