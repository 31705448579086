/* You can add global styles to this file, and also import other style files */
@import "./senet-theme.scss";
@import '~swagger-ui/dist/swagger-ui.css';

body {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-content: stretch;
    align-items: stretch;
    height: 100vh;
  }
   
  app-root {
    order: 0;
    flex: 1 1 auto;
    align-self: auto;
    display: block;
  }
  
  .contentContainer{
    height: 100%;
  }

.body-loading-spinner{
  width: 100px;
  height: 100px;
  position: absolute !important;
  left: 50%;
  top: 50%;
  margin-left: -50px;
  margin-top: -50px;
}

.mat-list-base .mat-list-item .mat-list-item-content, .mat-list-base .mat-list-option .mat-list-item-content {
  padding: 0 0 0 16px !important;
}


.container {
  max-height: 90vh;
}

.mat-sidenav-content, mat-sidenav-content, .senet-sidenav-content{
  max-height: 92vh !important;
  height: 100% !important;
}

.map-container{
  height: 100% !important;
  width: 100% !important;
}

.mat-sort-header-content{
  white-space: nowrap;
}