/* You can add global styles to this file, and also import other style files */
.commandMessageTable{
    width: 100%;
    text-align: center;
}

.commandMessageCode-error{
    color: red;
}

.commandMessageCode-sucess{
    color: green;
}

.event-last-occurrence{
    position: absolute;
    left: 25px;
    top: 13px;
    color:gray;
    font-size: 9px;
}

.mat-form-field-wrapper {
    padding-bottom: 1.34375em !important;
  }

.mat-sort-header-content{
  white-space: nowrap;
}